import React from 'react';
import API_URL from '../config/contentApi';

function HeroBanner({ data, ctaText, scrollToForm }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  const { Title, Body, Image, MobileImage } = data;

  const desktopImageUrl = Image ? `${API_URL}${Image.url}` : null;
  const mobileImageUrl = MobileImage ? `${API_URL}${MobileImage.url}` : null;

  const hasImage = desktopImageUrl || mobileImageUrl;

  return (
    <div className={`hero-banner ${hasImage ? 'has-image' : 'no-image'}`}>
      {/* Use the picture element for responsive images */}
      {(desktopImageUrl || mobileImageUrl) && (
        <picture>
          {mobileImageUrl && (
            <source media="(max-width: 1024px)" srcSet={mobileImageUrl} />
          )}
          {desktopImageUrl && (
            <img src={desktopImageUrl} alt={Title} className="hero-image" />
          )}
        </picture>
      )}
      <div className="overlay"></div>
      <div className="hero-banner-content">
        <h1>{Title}</h1>
        <p>{Body}</p>
        {ctaText && (
          <button onClick={scrollToForm}>{ctaText}</button>
        )}
      </div>
    </div>
  );
}

export default HeroBanner;
