import React from 'react';

function Header({ headerImage }) { // Accept headerImage as a prop
  return (
    <header className="global-header">
      <div className="logo">
        <img src={headerImage} alt="Logo" />
      </div>
    </header>
  );
}

export default Header;