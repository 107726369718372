import axios from 'axios';
import API_URL from '../config/contentApi';

const API_TOKEN = '96255e3e929c0f345976e9b21c0770c7e4f8c040cbb4d5e2acc769d8c0473f8c336973666222684b2be34bcf551f520b7083f59b86d6217b43be52bd1539fc480ad0ed80986c3eaa9c4dde9ab6f05c4597622b181c95ee2b1f7f0a762c54c8f69162b27973ed525a270da8d77c546c239a5d5999318aae0917d31dd1741b2a35';

const headers = {
  Authorization: `Bearer ${API_TOKEN}`,
};

// Define custom populate settings for different content types
const populateMap = {
  carousels: 'populate[Slide][populate]=*', // Custom populate for carousels
  testimonials: 'populate[Slide][populate]=*', // Custom populate for testimonial carousels
  // Add more content types here with specific populate fields if needed
};

export const fetchContentByTypeAndId = async (contentType, id) => {
  try {
    // Get the custom populate string if it exists, otherwise default to populate all
    const queryString = populateMap[contentType.toLowerCase()] || 'populate=*';

    // Construct the full endpoint with the correct query string
    const endpoint = `${API_URL}/api/${contentType}/${id}?${queryString}`;
    
    console.log('Fetching from endpoint:', endpoint); // This should now show the correct query

    // Make the request to Strapi
    const response = await axios.get(endpoint, { headers });

    return response.data;
  } catch (error) {
    console.error('Error fetching content:', error);
    throw error;
  }
};
