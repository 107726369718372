import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import API_URL from '../config/contentApi';

function Carousel({ carouselTitle, slides }) {
  if (!slides || slides.length === 0) {
    return <div>Loading carousel...</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
  };

  return (
    <div className="carousel-wrapper">
      {/* Carousel title */}
      {carouselTitle && <h2 className="carousel-title">{carouselTitle}</h2>}
      
      <div className="carousel">
        <Slider {...settings}>
          {slides.map((slide) => {
            const image = slide.Image?.[0]; // Safely access the first image if it exists
            const imageUrl = image?.formats?.large?.url || image?.url; // Get image URL or fallback

            return (
              <div key={slide.id} className="carousel-slide">
                {/* Left half: Image */}
                <div className="carousel-slide-image">
                  {imageUrl && (
                    <img
                      src={`${API_URL}${imageUrl}`}
                      alt={image?.alternativeText || slide.Title}
                    />
                  )}
                </div>
                
                {/* Right half: Title and Description */}
                <div className="carousel-slide-content">
                  <h3>{slide.Title}</h3>
                  <p>{slide.Description}</p>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;