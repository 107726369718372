export const contentMap = {
  'www.budget-blinds.us': {
    heroBanner: { contentType: 'hero-banners', id: 'glakiitzv1gxrtwnvqmwzunw' },
    columnSection: { contentType: 'column-blocks', id: 'ny6ct80r97iw7i8eavp16zyb' },
    secondColumnSection: { contentType: 'column-blocks', id: 'k14unhy37i1lnotto2yz3swe' },
    carousel: { contentType: 'carousels', id: 'lpqjqcl2yk31hfkj2mzw2z8i' },
    formBlock: { contentType: 'form-blocks', id: 'wcv7rtalcx7kua7uk0jxkd04' },
    headerImage: 'bbLogo.jpg',
    colors: {
      '--color-background-light': '#f5f5f5',
      '--color-primary': '#3B5072',
      '--color-secondary': '#6397AD',
      '--color-accent': '#E69B78',
      '--color-cta': '#E69B78',
      '--color-text-light': '#fff',
      '--color-text-dark': '#666',
      '--color-text-primary': '#3B5072',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'FREE CONSULTATION',
    variables: {
      'brooklyn': {
        city: 'Brooklyn',
        state: 'NY',
        cityState: 'Brooklyn, NY',
        phone: '917-692-4155',
        address: 'Local address',
        scriptParams: {
          i: '671a4e9ba607612bec51ebf7',
          s: '0b8630ed853e0030edee03c171f54bbc',
        },
        testimonial: { contentType: 'testimonials', id: 'u8u7a5ge99wwhn6pe45yurxl' },
      },
      'edison': {
        city: 'Edison',
        state: 'NJ',
        cityState: 'Edison, NJ',
        phone: '609-933-5685',
        address: 'Local address',
        scriptParams: {
          i: '671a4ecea607612bec51ebf8',
          s: 'cef93ecb0543128cd26f33fb0b229972', 
        },
        testimonial: { contentType: 'testimonials', id: 'f8ooboe9nz3w321bp9zsy1x0' },
      },
      'middletown': {
        city: 'Middletown',
        state: 'CT',
        cityState: 'Middletown, CT',
        phone: '860-613-6266',
        address: 'Local address',
        scriptParams: {
          i: '671a4f06a607612bec51ebf9', 
          s: '563c5211c58207e149fc0f7c90c0bfc9', 
        },
        testimonial: { contentType: 'testimonials', id: 'u4yo5mqcl6iy7pego7zivejz' },
      },      
    },
    gtmId: 'GTM-KX2MV7ZZ',
  },
  'www.tailoredcloset.us': {        
    heroBanner: { contentType: 'hero-banners', id: 'w182rnvabkkrlb77zawj2zdz' },
    columnSection: { contentType: 'column-blocks', id: 'bh6krc1tly9eyytdlri9k2s9' },
    secondColumnSection: { contentType: 'column-blocks', id: 'q0nzwyu0321v3v1sqjyeoi82' },
    carousel: { contentType: 'carousels', id: 'y0q4key6s3641pgs5xtve0dc' },
    formBlock: { contentType: 'form-blocks', id: 'lf11i4k0u6vmxc6wd14wekj6' },
    headerImage: 'ttLogo.jpg',
    colors: {
      '--color-background-light': '#f5f5f5',
      '--color-primary': '#3d3d3d',
      '--color-secondary': '#2d3a43',
      '--color-accent': '#2d3a43',
      '--color-cta': '#7b96b8',
      '--color-text-light': '#fff',
      '--color-text-dark': '#666',
      '--color-text-primary': '#3d3d3d',
      '--color-text-secondary': '#555',
      '--color-border': '#ccc',
      '--color-error': 'red',
      '--color-h1': 'black'
    },
    ctaText: 'FREE CONSULTATION',
    variables: {
      'carlsbad': {
        city: 'Carlsbad',
        state: 'CA',
        cityState: 'Temecula, CA',
        phone: '951-297-9858',
        address: 'Local address',
        scriptParams: {
          i: '671a4e69a607612bec51ebf6',
          s: 'ff905c2e3e943ec74022da6fef222d0e',
        },
      },
      testimonial: { contentType: 'testimonials', id: 'qdd4bqqa2gailvw3nqahtzvm' },
      'port-charlotte': {
        city: 'Port Charlotte',
        state: 'FL',
        cityState: 'Punta Gorda, FL',
        phone: '941-212-2824',
        address: 'Local address',
        scriptParams: {
          i: '671a4e9ba607612bec51ebf7',
          s: '0b8630ed853e0030edee03c171f54bbc',
        },
      },
      testimonial: { contentType: 'testimonials', id: 'wpyiim5u1y5xt5itulov4zk0' },
    },
    gtmId: 'GTM-MDRZFGGX',
  },
};