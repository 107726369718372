import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Header from './globalHeader'; 
import Footer from './globalFooter';
import HeroBanner from './heroBanner';
import ColumnSection from './columnSection';
import Carousel from './carouselSlider';
import FormBlock from './formBlock';
import Testimonial from './testimonialBlock';
import { fetchContentByTypeAndId } from '../services/strapiService';
import { contentMap } from '../config/contentMap';
import VigPixel from './vigPixel';

function DynamicPage() {
  const { key } = useParams();
  const [contentData, setContentData] = useState(null);
  const [error, setError] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [ctaText, setCtaText] = useState(null);
  const formRef = useRef(null);
  const [variables, setVariables] = useState(null);

  // Helper function to replace placeholders
  function replacePlaceholders(data, variables) {
    if (typeof data === 'string') {
      return data.replace(/\[([^\]]+)\]/g, (match, p1) => variables[p1] || match);
    } else if (Array.isArray(data)) {
      return data.map(item => replacePlaceholders(item, variables));
    } else if (typeof data === 'object' && data !== null) {
      const newData = {};
      for (const key in data) {
        newData[key] = replacePlaceholders(data[key], variables);
      }
      return newData;
    } else {
      return data;
    }
  }

    useEffect(() => {
    const domain = window.location.hostname;  // Get the domain
    const getAllContent = async () => {
      const mapping = contentMap[domain];
      if (!mapping) {
        setError(`No mapping found for domain: ${domain}`);
        return;
      }

      // Get variables for the key
      const variables = mapping.variables && mapping.variables[key];
      if (!variables) {
        setError(`No content found for key: ${key}`);
        return;
      }
      setVariables(variables); 
      console.log('key:', key);
      console.log('local vars:', variables);
      setHeaderImage(mapping.headerImage);
      setCtaText(mapping.ctaText);

      // Apply color variables from the mapping
      if (mapping.colors) {
        const root = document.documentElement;
        Object.entries(mapping.colors).forEach(([key, value]) => {
          root.style.setProperty(key, value);
        });
      }

      try {
        const heroBannerData = await fetchContentByTypeAndId(mapping.heroBanner.contentType, mapping.heroBanner.id);
        const columnSectionData = mapping.columnSection
          ? await fetchContentByTypeAndId(mapping.columnSection.contentType, mapping.columnSection.id)
          : null;
        const secondColumnSectionData = mapping.secondColumnSection 
          ? await fetchContentByTypeAndId(mapping.secondColumnSection.contentType, mapping.secondColumnSection.id)
          : null;
        const carouselData = mapping.carousel 
          ? await fetchContentByTypeAndId(mapping.carousel.contentType, mapping.carousel.id)
          : null;
        const formBlockData = await fetchContentByTypeAndId(mapping.formBlock.contentType, mapping.formBlock.id);
        
        // Update testimonial data fetching
        const testimonialData = variables.testimonial
          ? await fetchContentByTypeAndId(variables.testimonial.contentType, variables.testimonial.id)
          : null;

        // Process data to replace placeholders
        const processedHeroBannerData = replacePlaceholders(heroBannerData.data, variables);
        const processedColumnSectionData = columnSectionData ? replacePlaceholders(columnSectionData.data, variables) : null;
        const processedSecondColumnSectionData = secondColumnSectionData ? replacePlaceholders(secondColumnSectionData.data, variables) : null;
        const processedCarouselData = carouselData ? replacePlaceholders(carouselData.data, variables) : null;
        const processedFormBlockData = replacePlaceholders(formBlockData.data, variables);
        const processedTestimonialData = testimonialData ? replacePlaceholders(testimonialData.data, variables) : null;

        setContentData({
          heroBanner: processedHeroBannerData,
          columnSection: processedColumnSectionData,
          secondColumnSection: processedSecondColumnSectionData,
          carousel: processedCarouselData,
          formBlock: processedFormBlockData,
          testimonial: processedTestimonialData
        });
      } catch (error) {
        setError('Failed to fetch content.');
        console.error('Error fetching content:', error);
      }
    };

    getAllContent();
  }, []);


  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!contentData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {variables && variables.scriptParams && (
        <VigPixel
          iParam={variables.scriptParams.i}
          sParam={variables.scriptParams.s}
        />
      )}
      <Header headerImage={headerImage} />

      {/* Move HeroBanner outside of page-container and pass props */}
      <HeroBanner
        data={contentData.heroBanner}
        ctaText={ctaText}
        scrollToForm={scrollToForm}
      />

      <div className="page-container">
          {contentData.columnSection && <ColumnSection data={contentData.columnSection} />}
          {contentData.secondColumnSection && <ColumnSection data={contentData.secondColumnSection} />}
          {contentData.carousel && <Carousel carouselTitle={contentData.carousel.Title} slides={contentData.carousel.Slide} />}
          <FormBlock ref={formRef} data={contentData.formBlock} urlKey={key} city={variables.city} />
          {contentData.testimonial && <Testimonial data={contentData.testimonial} />}
      </div>
      <Footer />
    </div>
  );

}

export default DynamicPage;