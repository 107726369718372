import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DynamicPage from './components/dynamicPage';
import { contentMap } from './config/contentMap';
import GTM from './components/tagManager';

function App() {
  const domain = window.location.hostname;
  const mapping = contentMap[domain];
  const gtmId = mapping && mapping.gtmId;

  return (
    <>
      {gtmId && <GTM gtmId={gtmId} />} {/* Inject GTM based on the domain */}
      <Router>
        <Routes>
          <Route path="/:key" element={<DynamicPage />} /> {/* Use key in the URL */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
