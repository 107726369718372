import React from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import API_URL from '../config/contentApi';

// Use React.forwardRef to pass the ref down to the root element
const FormBlock = React.forwardRef(({ data, urlKey, city }, ref) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (formData) => {

    // if (!captchaToken) {
    //   alert('Please complete the reCAPTCHA.');
    //   return;
    // }

    const submissionData = { ...formData, key: urlKey, city };  // Add city to submissionData
    // const submissionData = { ...formData, key: urlKey, city, captchaToken };

    try {
      const response = await fetch(`https://cors-chat-548058780807.us-central1.run.app/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submissionData)
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Email sent successfully:', result);
        alert('Form submitted successfully!');
      } else {
        console.error('Error sending email:', result);
        alert(`Failed to send form: ${result.error}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  if (!data) {
    return <div>Loading form block...</div>;
  }

  const { Form, Image } = data;
  const imageUrl = Image && Image[0]?.url;

  const RECAPTCHA_SITE_KEY = 'your_reCAPTCHA_site_key';

      return (
        <div className="form-block" ref={ref}> 
          {/* Left column for the image */}
          <div className="form-block-left-column">
            {imageUrl && (
              <img
                src={`${API_URL}${imageUrl}`}
                alt={Image[0]?.alternativeText || 'Form image'}
              />
            )}
          </div>

          {/* Right column for the form */}
          <div className="form-block-right-column">
            <h2>{Form.Title}</h2>
            <p>{Form.Description}</p>

            {/* Form container */}
            <div className="form-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <label>
                  First name:
                  <input 
                    type="text" 
                    {...register('firstName', { required: 'First name is required' })} 
                  />
                  {errors.firstName && <p className="error">{errors.firstName.message}</p>}
                </label>

                <label>
                  Last name:
                  <input 
                    type="text" 
                    {...register('lastName', { required: 'Last name is required' })} 
                  />
                  {errors.lastName && <p className="error">{errors.lastName.message}</p>}
                </label>

                <label>
                  Email:
                  <input 
                    type="email" 
                    {...register('email', { 
                      required: 'Email is required', 
                      pattern: {
                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: 'Enter a valid email'
                      } 
                    })} 
                  />
                  {errors.email && <p className="error">{errors.email.message}</p>}
                </label>

                <label>
                  Zip code:
                  <input 
                    type="text" 
                    {...register('zip', { required: 'Zip code is required' })} 
                  />
                  {errors.zipCode && <p className="error">{errors.zipCode.message}</p>}
                </label>

                <label>
                  Phone:
                  <input 
                    type="tel" 
                    {...register('phone', { 
                      required: 'Phone number is required',
                      pattern: {
                        value: /^[0-9()\-\s]+$/,
                        message: 'Please enter a valid phone number (e.g., (123) 456-7890 or 123-456-7890)'
                      }
                    })} 
                  />
                  {errors.phoneNumber && <p className="error">{errors.phoneNumber.message}</p>}
                </label>

                {/* Invisible reCAPTCHA */}
  {/*              <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY}
                  size="invisible"
                  ref={(r) => { window.recaptchaRef = r; }}
                  onChange={onCaptchaChange}
                />*/}

                <button type="submit">SUBMIT</button>
              </form>
            </div>

            {/* Contact info container */}
            <div className="contact-info-container">
              {Form.Phone && <h2>Call us today.</h2>}
              {Form.Phone && (
                <p className="form-contact-text">
                  <a href={`tel:${Form.Phone}`} className="form-phone-link">
                    {Form.Phone}
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      );

});

export default FormBlock;