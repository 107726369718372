import React from 'react';
import Slider from 'react-slick';
import API_URL from '../config/contentApi';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Testimonial({ data }) {
  if (!data) {
    return <div>Loading testimonial...</div>;
  }

  const slides = data.Slide;

  if (!slides || slides.length === 0) {
    return <div></div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1,
    autoplay: false, // Match the main carousel's autoplay setting
    autoplaySpeed: 4000,
  };

  return (
    <div className="carousel-wrapper">      
      <div className="carousel">
        <Slider {...settings}>
          {slides.map((slide) => {
            const image = slide.Image?.[0]; // Safely access the first image if it exists
            const imageUrl = image?.formats?.large?.url || image?.url; // Get image URL or fallback

            return (
              <div key={slide.id} className="testimonial-slide">
                {/* Left half: Image */}
                <div className="testimonial-slide-image">
                  {imageUrl && (
                    <img
                      src={`${API_URL}${imageUrl}`}
                      alt={image?.alternativeText || slide.Title}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonial;