import React from 'react';

function ColumnSection({ data }) {
  if (!data) {
    return <div>Loading column section...</div>;
  }

  const { Title, Column } = data;

  return (
  <div className="column-section">
    {/* Conditionally render the section title if it exists */}
    {Title && <h2 className="column-section-title">{Title}</h2>}

    <div className="columns">
      {Column && Column.length > 0 ? (
        Column.map((col) => (
          <div
            key={col.id}
            className={`column ${col.Description ? 'with-description' : 'no-description'}`} // Conditionally add classes
          >
            <div className="column-title-container">
              <h3>{col.Title}</h3>
            </div>

            {col.Description && (
              <div className="column-description-container">
                <p>{col.Description}</p>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No columns available.</p>
      )}
    </div>
  </div>
  );
}

export default ColumnSection;
